import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import 'react-phone-input-2/lib/style.css'
import PhoneInput from 'react-phone-input-2'

// import "react-datepicker/dist/react-datepicker.css";
// import DatePicker from "react-datepicker";

// import { Country, State, City } from 'country-state-city';
import { allcourese } from '../components/courses';

const RegistrationForm = ({ selectedCourse }) => {


    const [loading, setLoading] = useState(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [contact, setContact] = useState('');
    const [course, setCourse] = useState('All Courses'); //selectedCourse && selectedCourse.id
    const [error, setError] = useState({
        name: '',
        email: '',
        contact: '',
        course: ''
    });

    const onSubmit = (e) => {
        e.preventDefault();
        const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        let error = {
            name: '',
            email: '',
            contact: '',
            course: ''
        }

        if(!name) {
            error.name = "Name cannot be empty";
        }
        if (!emailRegex.test(email)) {
            error.email = "Please enter valid email id";
        }
        if (!contact) {
            error.contact = "Please enter valid contact no.";
        }
        if (!course) {
            error.course = "Course not selected";
        }

        if (name && email && contact && course) {
            if (!error.name && !error.email && !error.contact && !error.course) {
                setLoading(true);
                let scriptURL = 'https://script.google.com/macros/s/AKfycbxPI69fuPGjKu4fplCRWUiEo2GYiWX2rDl6uUTqhSRKkcGKxxtZFTXJSWyyGnCE8WgdXg/exec'
                let formData = new FormData();
                let today = new Date();
                formData.append('name', name);
                formData.append('email', email);
                formData.append('contact', contact);
                formData.append('courses', course);
                formData.append('created', today);
        
                fetch(scriptURL, { method: 'POST', body: formData })
                    .then((response) => {
                        
                        window.location = '/thankyou';
                        //console.log('the submitted responsive data', response);
                        // if(selectedCourse && selectedCourse.paymentUrl) {
                        // } 
                    })
                    .catch((error) => {
                        setLoading(false);
                        alert("Something went wrong!!!");
                    });

                return {
                    name,
                    email,
                    contact,
                    course
                }
            } else {
                setError(error);
            }
        } else {
            setError(error);
        }
    }
    const courselist = allcourese.map((course) => ({
        label: course.title,
        value: course.id
    }))

    return (
        <div className="sam-contact-form">
            <div className="registrationform">
                <p><b>Welcome to your Journey towards the successful future in IT, you will be under good mentorship for your future.</b></p>
                <br />

                <form className="form" id="registrationform" action="" onSubmit={onSubmit}>
                    <div className="clearfix">
                        <div className={["col1 first", error.name ? "invalid" : ""].join(" ")}>
                            <label>Name</label>
                            <input name="name" className="col2 first" type="text" placeholder="Name" value={name} onChange={(e) => {
                                setName(e.target.value)
                                setError({
                                    ...error,
                                    name: ''
                                });
                            }}/>
                            {error && error.name ? <span className="error">{error.name}</span> : null}
                        </div>
                    </div>
                    <div className="clearfix">
                        <div className={["col1 first", error.email ? "invalid" : ""].join(" ")}>
                            <label>Email</label>
                            <input name="email" className="col2 last" type="text" placeholder="Email" value={email} onChange={(e) => {
                                setEmail(e.target.value);
                                setError({
                                    ...error,
                                    email: ''
                                });
                            }} />
                            {error && error.email ? <span className="error">{error.email}</span> : null}
                        </div>
                    </div>
                    <div className="clearfix">
                        <div className={["col1 first", error.contact ? "invalid" : ""].join(" ")}>
                            <label>Phone</label>
                            <PhoneInput
                                name="contact"
                                country={'in'}
                                placeholder="Enter phone number"
                                searchPlaceholder="Enter phone number"
                                value={contact}
                                onChange={(value, data, event, formattedValue) => {
                                    setContact(value);
                                    setError({
                                        ...error,
                                        contact: ''
                                    });
                                }} />
                            {error && error.contact ? <span className="error">{error.contact}</span> : null}
                        </div>
                    </div>
                    {/* <div className="clearfix">
                        <div className={["col1 first", error.course ? "invalid" : ""].join(" ")}>
                            <label>Selected course</label>
                            <Select name="course" isDisabled={true} className="react-select" defaultValue={{
                                label: selectedCourse.title,
                                value: selectedCourse.id
                            }} options={courselist} />
                            {error && error.course ? <span className="error">{error.course}</span> : null}
                        </div>
                    </div> */}
                    <br />
                    <div className="clearfix"><input type="submit" className="btn" disabled={loading} value={ loading ? "Please Wait..." : "Buy This Course"} /></div>
                </form>
            </div>
        </div>
    )
}

export default RegistrationForm;