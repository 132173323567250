import React from 'react';
import PageBanner from '../components/pagebanner';
import RegistrationForm from '../components/registrationform';
import Layout from '../layouts/main';
import {Link} from 'gatsby';

import { allcourese } from '../components/courses';
import SEO from '../components/seo';

const Registration = ({ location }) => {
    
    var search = location.search.substring(1);
    var searchData = Object.fromEntries(new URLSearchParams(search));
    let selectedCourse = searchData && searchData.course ? allcourese.filter(fi => fi.id == searchData.course)[0] : '';

    return(
        <Layout>
            <SEO title="Registration" />
            <PageBanner title="Registration" />
            <div className="section registration">
                <div className="innerwrap">
                    <div className="row">
                        <div className="grid-6 grid-sm-12 grid-xs-12">
                            <div className="registration-content">
                                <h1>Course Registration </h1>
                                {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,</p> */}

                                <br/>
                                {<>
                                    <h3>All Courses: </h3>
                                    {/* <p className="type">Course Contains</p> */}
                                    <ul className="list">
                                        {allcourese.map((fi, index) => (
                                            <li key={index}>{fi.title}</li>
                                        ))}
                                    </ul>
                                    {/* <span><Link to="/courses">Change Course</Link></span> */}
                                </>}
                            </div>
                        </div>
                        <div className="grid-6 grid-sm-12 grid-xs-12">
                            <RegistrationForm selectedCourse={selectedCourse} />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Registration;